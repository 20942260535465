body {
  background-color: #ffffff;
  color: #220901;
}

#pageTitle {
  text-align: center;
}

.Status {
  padding: 10px;
  margin: 10px;

  background-color: #d8d8d8;
}

#ServerStatus{
  width: 100px;
  height: 50px;
  margin: 1em;
}

#GetCrm{
  width: 100px;
  height: 50px;
  margin: 1em;
}

#textUpdate{
  width: 340px;
  height: 50px;
  display: flex;
  right: 50em;
  position: absolute;
  flex-direction: column;
  top: 6em;
}

#buttons{
  width: 100px;
  height: 50px;
  display: flex;
  flex-direction: column;
}

.LogBox{
  margin: 100px;
}